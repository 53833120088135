.chart .rv-xy-plot {
  cursor: crosshair;
}

.chart__resetButtonContainer {
  width: 100%;
}

.chart__resetButton {
  border: 1px solid #ddd;
  box-shadow: none;
  display: block;
  margin: auto;
  padding: 1px 10px 1px 10px;
}

.chart__chartContainer {
  display: flex;
}

.chart__labels {
  overflow-y: auto;
  overflow-x: hidden;
  width: 185px;
}

.chart__label {
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chart__noDataAvailable {
  background-color: #eeeeee;
  border: 1px solid #ddd;
  height: 100px;
  margin-bottom: 15px;
  padding-top: 45px;
  text-align: center;
}
