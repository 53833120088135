.customTable .customTable__titleContainer {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.customTable .ReactVirtualized__Table__headerRow {
  font-weight: 500;
  border-bottom: 1px solid #e4e4e4;
  color: var(--colour-text-800);
  background-color: var(--colour-white);
}

.customTable
  .ReactVirtualized__Table__headerRow
  .ReactVirtualized__Table__headerColumn {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.customTable
  .ReactVirtualized__Table__headerRow
  .ReactVirtualized__Table__headerColumn
  .icon {
  color: var(--link-colour);
  float: right;
  margin: 5px 15px 0 0;
}

.customTable .ReactVirtualized__Table__row {
  color: var(--colour-text-800);
  border-bottom: 1px solid #e4e4e4;
  outline: none;
}

.customTable .circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.customTable__filterRow {
  height: 30px;
  display: flex;
}

.customTable__filterRow .filterRow {
  display: flex;
  flex-direction: row;
}

.customTable__filterRow .validereInputBox {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}

.datatable__actionRow .csvDownloadButton {
  float: right;
  height: 100%;
  padding: 0px 15px;
}

.customTable__titleContainer .addButton {
  margin-left: auto;
}

.customTable__titleContainer .customTable__buttonRow {
  margin-left: auto;
}

.customTable__actionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
}

.noDataRow {
  margin-top: 10px;
  text-align: center;
  font-style: normal;
}

.filterRow {
  display: inline;
}

.filterRow .multipleDropdownInputWithSearch {
  margin-right: 10px;
}

.filterRow .multipleDropdownInputWithSearch #input-dropdown-addon {
  border-radius: 3px;
  padding: 3px 5px;
}

.filterRow .dropdownInputWithSearch {
  display: inline-block;
  margin-right: 10px;
}

.filterRow .dropdownInputWithSearch .dropdown {
  display: inline-block;
}

.filterRow .dropdownInputWithSearch .dropdown button {
  padding: 0 5px 0 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  display: inline-block;
  text-transform: capitalize;
}

.filterRow .dateRangeSelectorContainer {
  color: var(--colour-text-800);
  border: 1px solid var(--borderColour);
  border-radius: 3px;
  display: inline-block;
  height: 38px;
  padding: 8px 6px;
  vertical-align: top;
  white-space: nowrap;
}

.filterRow .link {
  margin-top: 3px;
}

.listTable .ReactVirtualized__Table {
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-top: 10px;
}

.listTable .ReactVirtualized__Table__headerRow {
  background: #f6f6f6 !important;
  border-bottom: 1px solid #ddd;
}
