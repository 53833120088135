.orderedList {
  overflow-y: auto;

  &__container {
    padding-left: 0;
    margin: 0;
  }

  &__item {
    list-style-type: none;
    font-size: 15px;
    padding: 15px;
    height: 60px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: white;
    cursor: grab;

    > * + * {
      margin-left: 20px;
    }
  }

  &__item--focus {
    pointer-events: all !important;
    cursor: grabbing;
    z-index: var(--zindex-popover);
  }
}

.orderedList.disabled {
  color: var(--colour-text-400);

  .orderedList__item {
    background-color: var(--colour-surface-75);
  }
}
