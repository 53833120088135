.formRows.selectInput {
  .react-select {
    &__control {
      border-radius: 3px;
      text-transform: capitalize;
    }
    // Bootstrap .input-group .form-control has z-index 2
    &__menu {
      z-index: var(--zindex-dropdown);
    }
  }
}
