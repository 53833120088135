@import "constants/index.js";

.lineChart {
  display: flex;
  flex-direction: column;

  &__chartLabel {
    fill: $textColour;

    text {
      font-size: 13px;
    }
  }

  &__chart {
    flex: 1 1 auto;
  }

  &__tick {
    font-weight: bold;
  }

  .horizontalGridline {
    .rv-xy-plot__grid-lines__line {
      stroke: #ccc;
      stroke-width: 1px;
    }
  }

  &__crosshair {
    .rv-crosshair__line {
      background: none;
      border-left: 1px dashed #666;
    }

    &Tooltip {
      background-color: white;
      border: 1px solid $borderColour;
      border-radius: 3px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
      width: 250px;
      padding: 10px 15px;
      color: $textColour;

      .tooltipTitle {
        border-bottom: 1px solid $borderColour;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .tooltip {
        &__row {
          display: flex;
          justify-content: space-between;
        }
        &__title,
        &__value {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &__value {
          flex: 0 0 auto;
          margin-left: 20px;
        }
      }
    }
  }

  &__legend {
    display: flex;
    flex-wrap: wrap;

    > * + * {
      margin-left: 10px;
    }
  }

  .legendItem {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 3px 5px;
    max-width: 250px;
    margin-bottom: 15px;

    &__text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__noData {
    text-align: center;
    padding-top: 150px;
  }
}
