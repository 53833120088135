.formRows .DayPickerInput {
  width: 100%;
}

.formRows .DayPickerInput input {
  color: #333333;
  height: 35px;
  padding-left: 8px;
  width: 100%;
}

.formRows .DayPickerInput .DayPicker-Day--selected {
  color: #333333;
}
