.container {
  display: flex;
}

.container-row {
  justify-content: space-between;
}
.container-column {
  flex-direction: column;
}

.linkText {
  padding-right: 5px;
  text-decoration: underline;
  cursor: pointer;
}
