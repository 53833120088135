.formRows {
  margin-bottom: 25px;
}

.form__title {
  line-height: 16px;
  text-align: right;
  text-transform: capitalize;
}

.form__title .icon {
  margin-left: 5px;
}

.form__header {
  margin-bottom: 15px;
}

.form__input {
  align-items: baseline;
  display: flex;
  flex-direction: row;
}
