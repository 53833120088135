.dateRangeSelectorContainer {
  display: inline-block;
  color: var(--colour-default);
  background-color: var(--colour-white);

  input {
    display: inline-block;
    border: 0;
    box-shadow: none;
    font-size: var(--fontSize-body);
  }

  .fromToInputBoxes {
    display: inline-block;
  }

  .dateRangeSelector__relativeDayRange {
    display: inline-block;
  }

  .dateRangeSelector__relativeDayRange:hover {
    cursor: pointer;
  }
}

.DayPicker-Day--outside:hover {
  background-color: var(--colour-white) !important;
}
