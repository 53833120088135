.customList {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding-top: 5px;
}

.customList__row {
  border-top: 1px solid #ddd;
}

.customList__noRowMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
