.container {
  border: 1px solid var(--colour-surface-200);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 10px;
  border-radius: 6px;
  cursor: text;

  &:hover {
    border-color: var(--colour-primary-100);
  }

  &.focus {
    border-color: var(--colour-primary-100);
  }

  &.disabled {
    color: var(--colour-text-400);
    background-color: var(--colour-surface-75);
    border: 1px solid var(--colour-surface-100);

    &.focus,
    &:hover {
      border: 1px solid var(--colour-surface-100);
    }
  }
}

.input {
  border: 0;
  margin: 0;
  height: 100%;
  flex: 1;
  outline: none;
  padding: 4px 0;

  &.withIcon {
    margin: 0 0 0 10px;
  }
}
