.value-container {
  display: flex;
  align-items: center;
  gap: 4px;

  .lock-icon {
    display: flex;
    color: var(--colour-text-400);
  }

  &.left {
    flex-direction: row;
    text-align: left;
  }

  &.right {
    flex-direction: row-reverse;
    text-align: right;
  }
}
