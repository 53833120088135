.formRows .TextBoxInput__formControl {
  border: 1px solid #ddd;
  box-shadow: none;
  display: inline-block;
  font-size: 14px;
  padding-left: 10px;
  min-width: 0;
}

.formRows .form__unit {
  margin-left: 5px;
  white-space: nowrap;
}
