@import "constants/index.js";

.overlayLoader {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: var(--zindex-layer-second);
  background-color: rgba(255, 255, 255, 0.75);
}

.message {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  position: relative;
  font-size: 18px;
}

.icon {
  color: $validereColour;
}
