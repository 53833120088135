.calendarOverlayContainer {
  width: 400px;
  background: #fff;
  position: absolute;
  margin: 10px 15px;
  left: 15px;
  z-index: var(--zindex-dropdown);
  box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3);
  border-radius: 5px;

  .divider {
    border-right: 1px solid #ddd;
    margin: 15px;
  }

  .relativeRangeOptionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      color: var(--link-colour);
      box-shadow: none;
      outline: 0;
    }
  }

  .DayPicker-Months {
    height: 255px;
  }

  .dateSelector {
    display: flex;
    flex-direction: row;
  }

  .calendarTimePicker {
    padding: 10px;

    .timePickerContainer {
      margin-bottom: 15px;

      .rc-time-picker-input {
        width: 120px;
      }

      &__date {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0;
        color: var(--link-colour);
      }

      .rc-time-picker {
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 5px;
        vertical-align: c;
      }
    }
  }
}
