.section {
  margin-bottom: 10px;

  & &__header {
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
}
