.wrapper {
  margin-bottom: 15px;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  font-size: 24px;
  color: var(--colour-primary-500);

  &--error {
    color: var(--colour-error);
  }

  &--disabled {
    color: var(--colour-surface-200);
  }
}
