.resizePanels {
  display: flex;
  flex-direction: row;

  .widthController {
    position: relative;
    cursor: ew-resize;

    .fa {
      font-size: 20px;
      position: relative;
      transform: rotate(90deg);
      top: 45%;
    }
  }

  .widthController:hover {
    background-color: #e2e5e8;
    z-index: var(--zindex-popover);
  }

  .collapsedTitle {
    transform: rotate(-90deg);
    position: relative;
    top: 33px;
    font-weight: bold;
    text-transform: uppercase;
  }
}
