@import "constants/mixins";

.testNote {
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px;
  margin: 10px;
  display: flex;
  background-color: white;

  > * + * {
    margin-left: 15px;
  }

  &__body {
    > * + * {
      margin-top: 10px;
    }
  }

  &__author {
    font-weight: bold;
  }

  &__text {
    text-align: justify;
    margin-bottom: 10px;
  }

  &__attachmentItem {
    margin-left: 10px;

    &__imageContainer {
      position: relative;
      padding: 4px;
      border: 1px solid #ddd;
      border-radius: 3px;
      margin-bottom: 20px;

      &.loading {
        height: 80px;
      }
    }

    &__image {
      display: block;
      margin: 0 auto;
      width: 80%;

      &.loading {
        display: none;
      }
    }
  }
}
