.dropdownList {
  &__container {
    border: 1px solid #ddd;
    height: 545px;
    padding: 10px;
    overflow-y: scroll;

    .dropdownInputRow {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .title {
        display: inline;
        text-transform: capitalize;
      }

      & .dropdown {
        float: right;
        border-radius: 0;
      }

      & .dropdown button {
        box-shadow: none;
        border: 1px solid #ddd;
        border-radius: 0;
        padding: 3px 10px 3px 10px;
        text-transform: capitalize;
        width: 125px;
      }

      & .dropdown-menu > li > a {
        text-transform: capitalize;
      }
    }
  }
}
