@import "constants/mixins";

// REVIEW: this file needs a lot of cleanup. I originally changed this from a .scss file to a .module.scss - the styles here relied on strange inheritance to work.

.summaryContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  grid-gap: 8px 86px;
  overflow: hidden;

  &.status {
    padding-top: 40px;
    text-transform: capitalize;
  }

  &.vertical {
    flex-direction: column;

    .summaryInformation__title {
      white-space: normal;
    }
  }

  > .summaryInformation__title {
    color: var(--colour-text-400);
  }

  > .summaryInformation__value {
    text-transform: capitalize;
  }
}

.statusContainer {
  top: 0;
  height: 56px;
  width: 100%;
  margin: 0px -20px;
  border-radius: 8px 8px 0px 0px;
  position: absolute;
  background: var(--colour-surface-100);

  &.active {
    background: var(--colour-success-light);
  }
  &.success {
    background-color: var(--colour-success-light);
  }
  &.inactive {
    background: var(--colour-background-dark);
  }
  &.info {
    background: var(--colour-default-light);
  }
  &.warning {
    background: var(--colour-warning-light);
  }
  &.error {
    background-color: var(--colour-error-light);
  }
  &.primary {
    background-color: var(--colour-primary-50);
  }
}

.pill {
  @include fontStyles(card, 12px, false, 700);
  display: inline-block;
  padding: 3px 14px 2px;
  border-radius: 20px;
  margin: 16px;
  background-color: #fff;
}

.pillIndicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0px 4px 0px 0px;
  display: inline-block;
  flex: none;
  order: 0;
  flex-grow: 0;
  background: var(--colour-text-200);

  &.active {
    background: var(--colour-success);
  }
  &.inactive {
    background: var(--colour-text-400);
  }
  &.info {
    background: var(--colour-default);
  }
  &.warning {
    background: var(--colour-warning);
  }
  &.success {
    background-color: var(--colour-success);
  }
  &.error {
    background-color: var(--colour-error);
  }
  &.primary {
    background-color: var(--colour-primary-500);
  }
}

.summaryInformation {
  padding: 10px 8px 0 0;
  overflow: hidden;
  display: flex;

  &__vertical {
    flex-direction: column;

    .summaryInformation__title {
      font-weight: normal;
    }

    .summaryInformation__value {
      font-weight: bold;
      margin-left: 0px;
    }
  }

  &__spaceBetween {
    justify-content: space-between;
  }

  &__title {
    display: flex;
    align-items: center;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--colour-text-400);
  }

  &__title--small {
    font-size: 13px;
  }

  &__title > :not(first-child) {
    padding-left: 8px;
  }

  &__value {
    margin-left: 5px;
    display: flex;
    flex-wrap: nowrap;
    @include fontStyles(body, 16);
    white-space: nowrap;

    .value,
    .estimatedValue {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .unit {
      margin-left: 2px;
    }

    .icon {
      font-size: 12px;
      margin-left: 5px;
    }
  }
}
