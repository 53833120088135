.container {
  display: flex;
  align-items: center;
  border: 1px solid var(--borderColour);
  border-radius: 8px;
  padding: 0 8px;
  font-size: 14px;

  .icon {
    color: var(--link-colour);
    font-size: 10px;
    margin-right: 8px;
  }
}
