.status {
  display: flex;
  flex-direction: row;
  text-transform: capitalize;

  &__dot {
    border-radius: 50%;
    height: 10px;
    margin: 7px 7px 0 0;
    width: 10px;
  }
}
